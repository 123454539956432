function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
var TimeCountdown = function(props) {
    var _useState = _sliced_to_array(useState(props.seconds), 2), seconds = _useState[0], setSeconds = _useState[1];
    var interval = useRef();
    useEffect(function() {
        if (seconds > 0) {
            interval.current = setInterval(function() {
                return setSeconds(function(prevSeconds) {
                    return prevSeconds - 1;
                });
            }, 1000);
        }
        return function() {
            return clearInterval(interval.current);
        };
    }, []);
    useEffect(function() {
        if (seconds === 0) {
            props.onFinish();
            clearInterval(interval.current);
        }
    }, [
        seconds
    ]);
    return "".concat(String(Math.floor(seconds / 60)).padStart(2, '0'), ":").concat(String(seconds % 60).padStart(2, '0'));
};
TimeCountdown.propTypes = {
    seconds: PropTypes.number,
    onFinish: PropTypes.func
};
TimeCountdown.defaultProps = {
    seconds: 600,
    onFinish: function() {}
};
export { TimeCountdown };
